.ola-card_title {
  font-size: 18px; }

.ola-cards_list {
  display: flex; }

@font-face {
  font-family: "Icons";
  src: url("../../assets/fonts/icomoon.ttf") format("truetype"); }

.removerFila{
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-self: flex-start;
  padding-top: 15px;
}

#toast-container {
  top: 20% !important;
}

.channel-codename {
  align-items: center;
  display: flex;
  margin: 10px 0 0; }
  .channel-codename.channel-agregaded-verified:after, .channel-codename .channel-agregaded-verified:after {
    color: #93cb6f;
    content: "\e900";
    font-family: "Icons";
    font-size: 1.22em;
    margin-left: calc(5px / 2); }
  .channel-codename.channel-agregaded:after, .channel-codename .channel-agregaded:after {
    color: #f7941e;
    content: "\e901";
    font-family: "Icons";
    font-size: 1.1em;
    margin-left: calc(5px / 2); }
  .channel-codename.channel-verified:after, .channel-codename .channel-verified:after {
    color: #00c2ff;
    content: "\e903";
    font-family: "Icons";
    font-size: 1.22em;
    margin-left: calc(5px / 2); }
  .channel-codename.channel-premium:after, .channel-codename .channel-premium:after {
    content: "\e903";
    color: #FFD600;
    font-family: "Icons";
    font-size: 1.22em;
    margin-left: calc(5px / 2); }

.section-title {
  font-size: 18px; }

.section-subtitle {
  font-size: 16px; }

.editorial .episode-card {
  padding: 5px;
  max-width: 500px; }
  .editorial .episode-card .episode-thumb {
    margin-right: 5px; }

.editorial .editorial_title {
  font-size: 18px; }

.editorial .editorial_list {
  display: flex; }

.verticals .vertical-title {
  display: flex;
  flex-direction: column; }
  .verticals .vertical-title small.section-subtitle {
    font-size: 14px;
    margin-top: 5px; }

.vertical-lists {
  display: flex; }
  .vertical-lists .vertical-itens {
    margin-right: 5px; }
  .vertical-lists .episode-card {
    margin-right: 5px; }
    .vertical-lists .episode-card .episode-thumb {
      margin-right: 5px; }

.header-ola {
  box-shadow: rgba(0, 0, 0, 0.2) 0 -1px 3px 1px;
  height: 100px;
  transition: 0.2s; }

.form-search {
  display: flex;
  flex: 1;
  position: relative; 
  max-width: 335px;
  margin: auto;
}
.form-search button {
  position: absolute;
  right: 0;
  top: 10px; 
}
.form-search a {
  position: absolute;
  left: 12px;
  top: 10px; 
}
.form-search input {
  margin-bottom: 0px !important; 
  height: 40px !important;
  border-radius: 27.17px !important;
  background-color: #F2F2F2 !important;
  padding-left: 40px !important;
}

.vertical-itens .vertcial-thumb_wrapper {
  padding: 20px;
  display: flex;
  justify-content: center; }

.vertical-itens .episode-card {
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 3px; }
  .vertical-itens .episode-card .episode-thumb {
    border-radius: 3px; }

.vertical-itens .card-premium {
  color: #fff; }
  .vertical-itens .card-premium .play-icon {
    color: #fff; }

.vertical-itens .episode-card,
.vertical-itens .card-premium {
  padding: 5px;
  width: 100%; }

.vertical-itens.vertical-market .card-premium {
  background: #00784a; }

.vertical-itens.vertical-daily .card-premium {
  background: #e52221; }

.vertical-itens.vertical-family .card-premium {
  background: #0eaddb; }

.vertical-itens.vertical-knowledge .card-premium {
  background: #d94c75; }

.vertical-itens.vertical-sports .card-premium {
  background: #65b32d; }

.vertical-itens.vertical-history .card-premium {
  background: #5e4596; }

.vertical-itens.vertical-humor .card-premium {
  background: #ffcc00; }

.vertical-itens.vertical-lifestyle .card-premium {
  background: #b39abc; }

.vertical-itens.vertical-variety .card-premium {
  background: #f08332; }

.bg-secondary {
  background-color: #EEE;
  padding: 15px;
  width: 100%; }

.center-flex-content {
  display: flex;
  justify-content: center;
  align-items: center; }

.start-flex-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
 }
  
.button-primary {
  align-items: center;
  background: #2196F3;
  border-width: 0;
  border-radius: 26px;
  border-radius: 3px !important;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: Avenir;
  font-size: 11.5px;
  height: 35px;
  justify-content: center;
  line-height: 16px;
  padding: 0 15px;
  text-transform: uppercase;
  width: 100%; }
  .button-primary.disabled {
    background: #CCC !important;
    cursor: not-allowed; }

.button-secondary {
  align-items: center;
  background: #CCC !important;
  border-width: 0;
  border-radius: 26px;
  border-radius: 3px !important;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-family: Avenir;
  font-size: 11.5px;
  height: 35px;
  justify-content: center;
  line-height: 16px;
  padding: 0 15px;
  text-transform: uppercase;
  width: 100%; }
  .button-secondary.disabled {
    background: #CCC !important;
    cursor: not-allowed; }

.contentUserSession {
  display: flex;
  align-items: center; }

.contentUserImage {
  width: 2.334rem;
  height: 2.334rem;
  border-radius: 7px;
  overflow: hidden; }

.cardTimCarro {
  background: #04234A;
  border-radius: 7px;
  padding: 20px;
  overflow: hidden; }

.cardTimCarroHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cardTimCarroLogo {
  width: 57px;
  height: 16px;
}

.cardTimCarroTitle {
  height: 17px;
  /* width: 162px; */
  color: #DBDBDB;
  font-family: Avenir;
  font-size: 11px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 17px;
  margin-left: 15px;
}

.cardTimCarroText {
  height: 34px;
  /* width: 249px; */
  color: #DBDBDB;
  font-family: Avenir;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 17px;
  margin-top: 13px;
  margin-bottom: 13px;
}

.cardTimCarroButton {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 174px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 20.5px;
  background-color: #E1355A;
}

.cardTimCarroButtonText {
  height: 16px;
  width: 131px;
  color: #FFFFFF;
  font-family: Avenir;
  font-size: 12px;
  font-weight: 900;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
}

div#modal-tim1 {
  width: 375px;
  border-radius: 22px;
  padding: 30px;
}

div#modal-tim2 {
  width: 375px;
  border-radius: 22px;
  padding: 30px;
}

.contentUserName {
  margin: 0 10px; }

.header-user-image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }
.header-user-image.mobile {
  padding: 0;
}
.bg-header-container {
  width: 100% !important; }

.block-contanier-footer {
  height: inherit !important;
  width: 100% !important;
  margin-bottom: 0px !important; }

.config-container {
  box-shadow: rgba(0, 0, 0, 0.15) 0 0 3px; }

.hidden {
  display: none; }

.channel-page {
  padding-left: 0px !important;
  width: 100%; }

@media only screen and (max-width: 992px) {
  .header-ola {
    max-height: 75px; }
    .header-ola .logo-image {
      max-height: 90px; } }

.episode-channel_wrapper {
  display: flex;
  align-items: center;
  width: 100px;
  margin-top: 5px; }
  .episode-channel_wrapper .channel-type {
    margin-left: 5px;
    color: #93cb6f;
    width: 16.62px; }

.channel-title {
  color: #333;
  font-style: normal;
  font-weight: bold;
  font-size: 21px;
  line-height: 28px; }

.channel-codename {
  color: #666;
  font-style: normal;
  font-weight: normal;
  font-size: 0.9em;
  line-height: 16px;
  text-transform: uppercase; }

.tabs .tab a {
  color: #666; }
  .tabs .tab a:hover {
    color: #2196F3; }
  .tabs .tab a.active {
    color: #2196F3; }
  .tabs .tab a:focus {
    background-color: #fbfdff !important; }

.tabs .indicator {
  background-color: #2196F3; }

.channel-page.episode-list {
  height: auto !important;
  min-height: 100%;
  padding: 15px !important; }

.trend-list {
  display: flex; }

.container {
  width: 92% !important; }

.editorial-card {
  cursor: pointer;
  min-width: 350px;
  display: flex;
  border-radius: 3px;
  padding: 15px; }
  .editorial-card .card-top_text {
    text-transform: uppercase; }
  .editorial-card .card-title_text {
    font-size: 16px;
    font-weight: bold;
    line-height: 18px; }
  .editorial-card .card-wrapper_img {
    /* max-width: 150px; */
    width: 100%;
    height: 100%;
    padding: var(--spacing-small); 
    border-radius: 3px;
    overflow: hidden;
  }

.episode-page .episode-title {
  font-size: 2.33em;
  font-weight: bold;
  text-align: center; }

.footer-block {
  display: flex; }
  .footer-block .time-ago {
    align-self: flex-end; }

.account-item .material-icons {
  color: #9e9e9e; }

.menu-account-sidenav {
  padding: 15px; }
