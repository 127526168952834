body {
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  min-height: 100%;
  height: auto !important;
  width: 100%; }
/* LOADING INICIO */
.contentPurpleSmalGrenn{ animation: col1 10s infinite; }
@keyframes col1 {
    0% { y:90; height: 62px; fill: #ffcc00; }
    10% { y:236; height: 62px; fill: #f08332; }
    20% { y:115; height: 62px; fill: #ad97c8; }
    30% { y:90; height: 200px; fill: #65b32e; }
    40% { y:180; height: 65px; fill: #e52321; }
    50% { y:200; height: 65px; fill: #00784a; }
    60% { y:185; height: 60px; fill: #d94c75; }
    70% { y:180; height: 65px; fill: #5e4696; }
    80% { y:180; height: 65px; fill: #0eaddb; }
    90% { y:180; height: 60px; fill: #81c69d; }
    100% { y:90; height: 62px; fill: #ffcc00; }
}
.contentBlue{ animation: col2 10s infinite; }
@keyframes col2 {
    0% { y: 90; height: 201px; fill: #ffcc00; }
    10% { y:109; height: 201px; fill: #f08332; }
    20% { y:133; height: 200px; fill: #ad97c8; }
    30% { y:155; height: 150px; fill: #65b32e; }
    40% { y:105; height: 210px; fill: #e52321; }
    50% { y:109; height: 201px; fill: #00784a; }
    60% { y:160; height: 110px; fill: #d94c75; }
    70% { y:110; height: 200px; fill: #5e4696; }
    80% { y:160; height: 100px; fill: #0eaddb; }
    90% { y:180; height: 60px; fill: #81c69d; }
    100% { y: 90; height: 201px; fill: #ffcc00; }
}
.contentGreen{ animation: col3 10s infinite; }
@keyframes col3 {
    0% { y:145; height: 151px; fill: #ffcc00; }
    10% { y:140; height: 151px; fill: #f08332; }
    20% { y:150; height: 151px; fill: #ad97c8; }
    30% { y:165; height: 145px; fill: #65b32e; }
    40% { y:140; height: 150px; fill: #e52321; }
    50% { y:133; height: 65px; fill: #00784a; }
    60% { y:120; height: 190px; fill: #d94c75; }
    70% { y:140; height: 150px; fill: #5e4696; }
    80% { y:140; height: 150px; fill: #0eaddb; }
    90% { y:140; height: 150px; fill: #81c69d; }
    100% { y:145; height: 151px; fill: #ffcc00; }
}
.contentRed{ animation: col4 10s infinite; }
@keyframes col4 {
    0% { y:110; height: 250px; fill: #ffcc00; }
    10% { y:90; height: 250px; fill: #f08332; }
    20% { y:100; height: 250px; fill: #ad97c8; }
    30% { y:175; height: 70px; fill: #65b32e; }
    40% { y:90; height: 250px; fill: #e52321; }
    50% { y:90; height: 250px; fill: #00784a; }
    60% { y:90; height: 250px; fill: #d94c75; }
    70% { y:90; height: 250px; fill: #5e4696; }
    80% { y:90; height: 250px; fill: #0eaddb; } 
    90% { y:90; height: 250px; fill: #81c69d; }
    100% { y:110; height: 250px; fill: #ffcc00; }
}
.contentOrange{ animation: col5 10s infinite; }
@keyframes col5 {
    0% { y:100; height: 247px; fill: #ffcc00; }
    10% { y:122; height: 185px; fill: #f08332; }
    20% { y:90; height: 250px; fill: #ad97c8; }
    30% { y:140; height: 145px; fill: #65b32e; }
    40% { y:90; height: 250px; fill: #e52321; }
    50% { y:240; height: 65px; fill: #00784a; }
    60% { y:120; height: 190px; fill: #d94c75; }
    70% { y:180; height: 65px; fill: #5e4696; }
    80% { y:90; height: 250px; fill: #0eaddb; }
    90% { y:90; height: 250px; fill: #81c69d; }
    100% { y:100; height: 247px; fill: #ffcc00; }
}
.contentLightBlue{ animation: col6 10s infinite; }
@keyframes col6 {
    0% { y:103; height: 151px; fill: #ffcc00; }
    10% { y:90; height: 200px; fill: #f08332; }
    20% { y:150; height: 150px; fill: #ad97c8; }
    30% { y:125; height: 145px; fill: #65b32e; }
    40% { y:140; height: 150px; fill: #e52321; }
    50% { y:140; height: 150px; fill: #00784a; }
    60% { y:160; height: 110px; fill: #d94c75; }
    70% { y:140; height: 150px; fill: #5e4696; }
    80% { y:175; height: 65px; fill: #0eaddb; }
    90% { y:140; height: 150px; fill: #81c69d; }
    100% { y:103; height: 151px; fill: #ffcc00; }
}
.contentPurple{ animation: col7 10s infinite; }
@keyframes col7 {
    0% { y: 90; height: 62px; fill: #ffcc00; }
    10% { y: 176; height: 62px; fill: #f08332; }
    20% { y: 171; height: 62px; fill: #ad97c8; }
    30% { y: 135; height: 200px; fill: #65b32e; }
    40% { y:175; height: 65px; fill: #e52321; }
    50% { y:140; height: 65px; fill: #00784a; }
    60% { y:185; height: 60px; fill: #d94c75; }
    70% { y:175; height: 65px; fill: #5e4696; }
    80% { y: 175; height: 65px; fill: #0eaddb; }
    90% { y: 175; height: 65px; fill: #81c69d; }
    100% { y: 90; height: 62px; fill: #ffcc00; }
}
/* LOADING FINAL*/
main {
  padding-left:0;
  height: 100%; }

button,
a {
  cursor: pointer; }

#root {
  display: flex;
  flex-direction: column; }

.channel-card-container {
  display: flex;
  overflow: hidden;
  position: relative; }
  .channel-card-container .channel-card-cover {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100px;
    position: absolute;
    width: 100%; }
  .channel-card-container .channel-card-content {
    display: flex;
    flex: 1;
    margin: 80px 0 0 15px;
    position: relative;
    z-index: 1; }

.navbar {
  border-right: 1px solid var(--color-third);
  position: sticky;
  min-height: 100vh;
  width: 250px;
  display: flex;
  margin-top: -65px;
  flex-direction: column;
  justify-content: space-between;
  top: 0; }
  .navbar .banner-advertising {
    margin-top: var(--spacing-small); }
  .navbar .navbar_menu {
    width: 100%;
    font-size: 14px;
    text-transform: capitalize; }
    .navbar .navbar_menu li {
      padding: var(--spacing-medium) 0; }
    .navbar .navbar_menu li.menu-item--no_padding {
      padding: 0; }
    .navbar .navbar_menu li.logo-ola a {
      margin: 19px 20px 16px 20px;
      display: flex;
    }
    .navbar .navbar_menu li.menu-item:hover {
      background: #e2e2e2; }
    .navbar .navbar_menu li.menu-item.active {
      background: #f2f2f2;
      font-weight: 700;
      font-weight: bold;
      color: #4a4a49; 
    }
    .navbar .navbar_menu li.menu-item.ocultos {
      display: none;
    }
    .navbar .navbar_menu .menu-image {
      height: 100%;
      width: 250px; }
    .navbar .navbar_menu .menu-item {
      display: flex;
      align-items: center;
      color: var(--color-negative); }
    .navbar .navbar_menu .menu-item a {
      display: flex;
      height: 100%;
      width: 100%; 
      align-items: center;
    }
    .navbar .navbar_menu .menu-item a span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    .navbar .navbar_menu .menu-item.item-title {
      border-top: 1px solid #eee;
      color: #4B4B4A;
      font-size: 15px;
      font-weight: 900;
      line-height: 24px;
      padding-left: 15px;
    }
    .navbar .navbar_menu .menu-icon {
      margin-left: 15px;
      margin-right: 15px;
      color: var(--color-third-darken);
      height: 22px; }
    .navbar .navbar_menu img.menu-icon {
      height: 25px;
      width: 25px;
      border-radius: 7px;
    }

.alos {
  display: flex;
  margin: 10px 0; }

.alo {
  margin-right: 13px; 
}
.alo-carrosel {
  display: flex;
  overflow: hidden;
  width: 100%;
}
.alo .alo-link {
  box-sizing: border-box;
  height: 62px;
  width: 62px;
  border: 2px solid #0E53DB;
  border-radius: 50%;
  background-color: #FFFFFF;
  padding: 4px;
}

.alo .alo-link .thumbAlo {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
}

.alo-border--listened {
  border: 2px solid #2196f3; }

.alo-border--to-listen {
  border: 2px solid #2196f3; }

.alo-modal {
  background: transparent;
  box-shadow: none;
  color: #fff; }
  .alo-modal .alo-modal_action {
    display: flex;
    justify-content: space-between; }
  .alo-modal .alo-modal_content {
    width: 100%;
    height: 300px;
    background-size: cover;
    background-position: center center; }
  .alo-modal .alo-modal_footer {
    display: flex;
    width: 100%; }
    .alo-modal .alo-modal_footer .alo-modal_slider {
      z-index: 0;
      background: #c4c4c4;
      width: 100%;
      border-radius: 3px;
      height: 5px;
      margin: 5px 1.5px; }
      .alo-modal .alo-modal_footer .alo-modal_slider .alo-progress_status {
        background: #2196f3;
        height: 5px; }

.alo-manage {
  height: 100%; }
  .alo-manage .card-title {
    margin-bottom: 40px; }
    .alo-manage .card-title h6 {
      font-weight: bold; }
  .alo-manage .alo-table .alo-header {
    background: #eee; }
  .alo-manage .alo-table .alo-time_ago {
    font-weight: 700; }

.close-icon {
  width: 11px;
  color: #fff; }

.episode-card {
  margin-right: 15px;
  width: 163px;
  min-width: 163px; }
  .episode-card .channel-link {
    display: flex; }
  .episode-card .description {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden; }
  .episode-card .episode-thumb .episode-thumb_image {
    cursor: pointer;
    display: block;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 3px;
    min-height: 163px;
    height: 100%;
    position: relative;
    width: 100%; }
  .episode-card .episode-thumb .episode-thumb-hover {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    position: absolute;
    flex: 1;
    display: none;
    align-items: center;
    justify-content: center; }
    .episode-card .episode-thumb .episode-thumb-hover .material-icons {
      color: #FFF; }
    .episode-card .dropdown-content {
      min-width: 190px;
    }
    .episode-card .episode-thumb .dropdown-content li > a,
    .episode-card .episode-thumb .dropdown-content li > span {
      color: #2196F3;
      font-size: 11px;
      text-transform: uppercase;
    }
    .episode-card .episode-thumb .isPremium {
      position: absolute;
      right: 0;
      bottom: 0;
      color: #fff;
      font-size: 10px;
      line-height: 18px;
      font-weight: bold;
      text-transform: uppercase;
      background-color: #0E53DB;
      padding-left: 6px;
      padding-right: 6px;
      border-radius: 3px 0 3px 0;
    }
    .episode-card .episode-thumb .isVisualize {
      height: 18px;
      width: 18px;
      border-radius: 3px 3px 0 0;
      background-color: #39B750;
      position: absolute;
      right: 60px;
      bottom: 0;
      text-align: center;
      line-height: 18px;
    }
    .episode-card .episode-thumb .episode-thumb-hover .play-icon {
      display: contents; }
      .episode-card .episode-thumb .episode-thumb-hover .play-icon .material-icons {
        font-size: 4em; }
    .episode-card .wrapper-block .queue-icon {
      top: 5px;
      position: absolute;
      right: 0; 
      min-height: 12px !important;
    }
    .episode-card .wrapper-block  .queue-icon .iconMenu {
      height: 12px;
      color: #ccc;
    }
  .episode-thumb:hover .episode-thumb-hover {
    display: flex; }
  .episode-card.horizontal {
    display: flex;
    margin-bottom: 15px;
    width: 100%; }
    .episode-card.horizontal .episode-thumb_image {
      min-height: 157px;
      min-width: 157px; }
  .episode-card .title-block {
    display: flex;
    flex-direction: column; 
    position: relative;
  }
  .episode-card .title-block a {
    display: flex;
    font-size: 13px;
    font-weight: 900;
    line-height: 18px;
  }
  .episode-card .title-block a:first-child {
    min-height: 54px;
  }
  .episode-card .wrapper-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 5px; }
  .episode-card .wrapper-block .queue-icon {
    width: 12px;
    height: 22px;
    margin-top: -2px;
    align-items: flex-start;
    justify-content: flex-end;
    top: 5px;
    position: absolute;
    right: 0;
    min-height: 12px !important;
  }
  .episode-card .wrapper-block .queue-icon .iconMenu {
    color: #000;
    font-size: 15px;
  }
  .episode-card .title {
    font-weight: 600;
    overflow: hidden; 
    margin-right: 10px;
  }
  .episode-card .title .typeContent {
    margin-right: 10px;
  }
  .episode-card .channel-code {
    margin-right: 5px;
    font-size: 0.8em;
    text-transform: uppercase; }
  .episode-card .footer-block {
    display: flex;
    font-size: 0.77em;
    justify-content: space-between;
    margin-top: 5px; }
  .episode-card .duration .play-icon {
    font-size: 0.7rem;
    margin-bottom: 2px;
  }
  .episode-card .episode-thumb {
    max-width: 100%; }
  .episode-card .dropdown-content {
    /* transform: scaleX(0.8) scaleY(0.8) !important; */
    left: 0px !important;
    right: 0;
    top: -145px !important;
    height: 143px !important;
    width: auto !important;
    border-radius: 10px;
    background-color: #0E53DB;
    padding: 10px 20px;
    box-shadow: 0 10px 11px -4px rgba(14,83,219,0.3);
    -webkit-transform: scaleX(1) scaleY(1) !important;
    -ms-transform: scaleX(1) scaleY(1) !important;
    transform: scaleX(1) scaleY(1) !important;
  }
  .episode-card .dropdown-content li {
    min-height: 30px;
    display: flex;
    align-items: center;
  }
  .episode-card .dropdown-content li.divider {
    display: none;
  }
  .episode-card .dropdown-content li > a {
    color: #FFFFFF !important;
    padding: 0;
    min-height: auto !important;
  }
  .episode-card .dropdown-content a > li {
    color: #FFFFFF !important;
    padding: 0;
    min-height: auto !important;
    text-transform: uppercase;
    font-size: 11px !important;
  }
  .episode-card .dropdown-content li > a, .episode-card .dropdown-content li > span {
    color: #2196f3;
    font-size: 11px !important;
    text-transform: uppercase;
  }
.play-icon {
  width: 1.22em;
  color: #66757f; }

.comment-box textarea {
  padding: 15px; }

.episode-page .comentarios {
  margin-top: 15px; }

.ola-card_title {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px; }

.ola-cards_list {
  display: flex; }
.contentOlaBanner{
  position: relative;
}
.ola-banner {
  height: 0 !important; 
  min-height: 0 !important;
  padding-bottom: 25%;
}
.ola-banner .carousel-item {
  height: 100%;
}
.ola-banner .carousel-item .slide-cover {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  width: 100%; 
}
.ola-banner .indicators {
  bottom: -20px;
}
.carousel .indicators .indicator-item {
  width: 8px;
  height: 8px;
  background-color: #EDEFF1;
}
.carousel .indicators .indicator-item.active {
  background-color: #FF9400;
  width: 12px;
  height: 12px;
  margin: 22px 4px;
}
.contentOlaBanner .banner-pagination {
  position: absolute;
  z-index: 9;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: -1px 70x rgba(0, 0, 0, 0.17);
  top: 50%;
  margin-top: -16.5px;
  right: -16.5px;
  box-shadow: -1px 7px 11px 0 rgba(0,0,0,0.17);
  transition: 0.15s ease-in-out;
}
.contentOlaBanner .banner-pagination:hover {
  height: 39px;
  width: 39px;
  margin-top: -19.5px;
  right: -19.5px;
}

.contentOlaBanner .banner-pagination:hover .paginationIcon {
  color: #0E53DB;
}
.contentOlaBanner .banner-pagination:first-child {
  left: -16.5px;
}
.contentOlaBanner .banner-pagination:first-child:hover {
  left: -19.5px;
}

.channel-header {
  width: 100%; }

.channel-cover {
  height: 0;
  padding-bottom: 25%!important;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  background-color: #f5f5f5; }
  @media only screen and (max-width: 992px) {
    .channel-cover {
      height: 151px; 
    }
    .episode-card .episode-thumb .episode-thumb_image {
      /* height: auto; */
    }
    .episode-card.horizontal .episode-thumb_image {
      min-height: 100px;
      min-width: 100px;
    }
  }

.channel-card {
  display: flex; }
  .channel-card .channel-thumb {
    background-color: #CCC;
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 0 3px 1px;
    margin-top: -25px;
    overflow: hidden;
    height: 175px;
    width: 175px; }
    .channel-card .channel-thumb img {
      height: 100%;
      width: 100%; }
    @media only screen and (max-width: 992px) {
      .channel-card .channel-thumb {
        min-width: 70px;
        min-height: 70px;
        width: 0;
        height: 0;
        flex-wrap: wrap; } }
  .channel-card .channel-info {
    padding: 7px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
  .channel-card .channel-name {
    font-size: 23px;
    line-height: 28px;
    width: 100%;
    display: block; }
    @media only screen and (max-width: 992px) {
      .channel-card .channel-name {
        font-size: 14px;
        line-height: 16px;
        font-weight: 700; } }
  .channel-card .channel-codename {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.05em;
    width: 100%;
    display: flex; }
  .channel-card .subscribe-button {
    margin-top: var(--spacing-half-big);
    width: 155px; }
    @media only screen and (max-width: 992px) {
      .channel-card .subscribe-button {
        height: 135px; } }
  .channel-card .subscribers-count {
    margin-left: var(--spacing-small); }

.channel-subscribe {
  display: flex;
  align-items: baseline; }

.channel-verticals {
  margin-top: var(--spacing-small);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  height: 123px; }
  .channel-verticals .channel-alo {
    width: 155px;
    border: 1px solid #fc0;
    border-radius: 26px;
    height: 26px; }
    @media only screen and (max-width: 992px) {
      .channel-verticals .channel-alo {
        width: 135px; } }
  .channel-verticals .channel-wrapper_image {
    width: 155px;
    text-align: center;
    margin-top: var(--spacing-half-big); }
    .channel-verticals .channel-wrapper_image .vertical-image {
      margin-left: var(--spacing-small); }
  @media only screen and (max-width: 992px) {
    .channel-verticals {
      flex-direction: row;
      align-items: center;
      height: 50px; }
      .channel-verticals .channel-wrapper_image {
        margin-top: 0; } }

.subscribers {
  align-items: center;
  display: flex;
  height: auto;
  margin-top: 15px; }
  .subscribers .subscribers-count {
    margin-left: 17px; }
  .subscribers .btn {
    max-width: 115px; }

.channel-is_admin {
  background: #f5f5f5;
  display: flex;
  margin: 0 7px;
  padding: var(--spacing-medium) var(--spacing-small); }
  .channel-is_admin .admin-button {
    margin-right: 21px; }
  .channel-is_admin .admin-button_config {
    border: 1px solid #2196f3; }

.settings .admin-button_settings {
  display: flex;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.25) 0 2px; }

.admin-icon {
  width: 21px; }

.admin-icon--large {
  width: 35px; }

.player {
  width: 100%;
  height: auto;
  position: sticky;
  bottom: 0;
  background: #fff;
  line-height: 0; }
#playlist.dropdown-content{
  overflow: hidden;
  transform: none !important;
}
@keyframes marquee {
  0% {
    left: 0%; }
  100% {
    left: -100%; } }
  .player .row {
    margin: 0; }
  .player .col {
    padding: 0; }
  .player .track-title {
    width: auto;
    text-align: center;
    padding: 0;
    margin: 0 10px 0 0;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }
  .player .marquee-wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 24px;
    margin-bottom: 12px;
    justify-content: center;
    align-items: center; }
  .player .player-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background-color: #fff;
    padding: 0 12px; }
    @media only screen and (max-width: 992px) {
      .player .player-content {
        flex-direction: column; } }
  .player .player-thumbnail-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 25px;
    margin: 0 30px 0 0; }
    .player .player-thumbnail-wrapper img {
      border-radius: 4px; }
  .player .player-navigation-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1 0 0; }
    @media only screen and (max-width: 992px) {
      .player .player-navigation-wrapper {
        width: 100%; } }
  .player .player-title {
    width: auto;
    color: #666;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; }
    .player .player-title h4 {
      text-transform: uppercase;
      font-size: 10px;
      font-family: "Avenir-Book", sans-serify;
      margin: 0 4px 0 0; }
  .player .player-navigation {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center; }
  .player .player-actions li {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #eee;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    margin-right: 1rem; }
    .player .player-actions li .player-actions_icon {
      color: #989898;
      height: var(--size-small); }
    .player .player-actions li .player-actions_icon_active {
      color: #00b2ff; }
  .player .playlist-wrapper {
    width: auto;
    height: 2rem;
    background: #fff;
    display: flex;
    align-items: center;
    padding: 0; }
    .player .playlist-wrapper .dropdown-content {
      width: 300px;
      height: 380px; }
  .player .playlist-icon {
    color: var(--color-negative);
    height: 3rem; }
  .player .playlist-icon--rigth {
    margin-left: auto; }
  .player .player-track-wrapper {
    display: flex;
    flex-direction: row;
    flex: 1; }
  .player .player-controls {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; }
    .player .player-controls .player-control-inactive {
      cursor: default;
      opacity: 0.12; }
  .player .player-prev-button {
    color: #666;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.5s; }
  .player .player-next-button {
    color: #666;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: opacity 0.5s; }
  .player .player-play-button {
    color: #666;
    padding: 12px;
    cursor: "pointer";
    border: "none";
    display: flex;
    justify-content: center;
    align-items: center; }
  .player .player-track {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    padding: 0 3rem; }
  .player .player-progress {
    width: 100%;
    padding: 0; }
  .player .player-timer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1.7rem;
    color: var(--color-third-darken);
    font-size: var(--size-very-small); }
  .player .player-volume-control {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem; }
    .player .player-volume-control svg {
      color: #00b2ff; }
    .player .player-volume-control .volume-dropdown-trigger {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer; }
    .player .player-volume-control .dropdown-content {
      width: 200px;
      height: auto; }
      .player .player-volume-control .dropdown-content li {
        cursor: default;
        padding: 12px 20px 12px 12px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center; }
        .player .player-volume-control .dropdown-content li:hover {
          background-color: #fff; }
        .player .player-volume-control .dropdown-content li a {
          margin-right: 12px;
          padding: 6px; }
  .player .playlist-item {
    display: flex;
    flex-direction: row;
    padding: 5px;
    border-bottom: 1px solid #ccc; }
    .player .playlist-item:hover .track-title {
      width: auto;
      animation: marquee 5s linear infinite;
      position: relative; }
    .player .playlist-item figure {
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center; }
      .player .playlist-item figure img {
        border-radius: 4px; }
  .player .playlist-title {
    color: #000;
    padding: 12px;
    cursor: default; }
    .player .playlist-title:hover {
      background-color: #fff; }
    .player .playlist-title h3 {
      font-size: 14px;
      font-weight: 700;
      font-size: 14px;
      margin: 0; }
  .player .playlist-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin: 0 12px;
    flex: 1;
    overflow: hidden; }
    .player .playlist-content .track-title {
      width: 223px;
      margin: 0;
      text-align: left; }
  .player .show-on-medium-and-down {
    width: 100%; }
    .player .show-on-medium-and-down .player-responsive-navigation {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center; }

.breadcrumb-container {
  background: rgba(102, 117, 127, 0.3);
  color: #fff; }

.channel-admin_container {
  height: 100%; }
.header-user-image.mobile {
  min-width: 40px;
  min-height: 40px;
  max-width: 40px;
  max-height: 40px;
}
.dropdown-mobile {
  width: 250px;
  background-color: #fff;
  position: absolute;
  top: 60px;
  left: 20px;
  z-index: 1;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.dropdown-mobile .dropdown-profile_item a {
  color: #000; 
  width: 250px;
  padding: 15px;
  display: block;
}

.dropdown-profile {
  width: 250px !important; }
  .dropdown-profile-premium {
    min-height: 542px;
  }
.dropdown-profile .dropdown-profile_item {
  /*padding: 20px 27px;*/
  min-height: auto;
}
.dropdown-profile .dropdown-profile_item a {
  color: #000; 
  padding: 16px 18px;
}
.dropdown-profile li > a {
  font-size: 12px; }
.dropdown-profile .dropdown-profile_premium {
  border: 1px solid #2196f3;
  text-transform: uppercase;
  color: #2196f3 !important;
  font-size: 12px;
  padding: 5px 15px !important;
  text-align: center;
  border-radius: 3px;
  margin: 18px;}

.episode-player .episode-player_controls {
  text-align: center; }
  .episode-player .episode-player_controls .episode-player_icon {
    color: #666;
    height: 3.5em; }
    .episode-player .episode-player_controls .episode-player_icon.disabled {
      color: #eee;
      height: 3.5em; }
  .episode-player .episode-player_controls .play-pause {
    height: 5em; }

.episode-player .episode-player_slider {
  margin: 32px 0; }

.episode-player .episode-player_time {
  display: flex;
  justify-content: space-between;
  color: #c4c4c4;
  font-size: 10px; }

.episode-player .episode-player_actions {
  display: flex;
  justify-content: space-between; }
  .episode-player .episode-player_actions .player-actions_button {
    color: #989898;
    font-size: 14px;
    text-align: center; }
    .episode-player .episode-player_actions .player-actions_button .icon {
      height: 22px; }
    .episode-player .episode-player_actions .player-actions_button .icon:hover,
    .episode-player .episode-player_actions .player-actions_button .icon:active {
      color: #00b2ff; }

.horizontal-player {
  background-color: #FFF;
  bottom: 0;
  box-shadow: rgba(0, 0, 0, 0.1) 0 0 6px 1px;
  display: flex;
  height: 90px;
  left: 0;
  padding: 10px 15px;
  position: fixed;
  z-index: 1;
  right: 0;
  transition: 0.5s; }
  .horizontal-player .positionPlayer {
    position: absolute;
    top: -20px;
    background-color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 60px;
    height: 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 -4px 4px 1px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    text-transform: uppercase;
  }
  .horizontal-player.off .positionPlayer {
    display: none;
  }
  .horizontal-player.off {
    bottom: -90px; }
  .horizontal-player.hidden {
    bottom: -90px;
  }
  .horizontal-player .player-thumb {
    border-radius: 3px;
    height: 70px;
    width: 70px; }
    .horizontal-player .player-thumb img {
      height: 100%;
      width: 100%; }
  .horizontal-player .player-controller {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    margin: 0 15px; }
  .horizontal-player .player-timeline {
    flex: 2;
    flex-direction: column;
    margin: 0 15px; }
    .horizontal-player .player-timeline .player-time {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      font-size: 0.88em;
      color: #AAA; }
    .horizontal-player .player-timeline .player-epispode-title, .horizontal-player .player-timeline .player-channel {
      display: flex;
      justify-content: center;
      width: 100%; }
    .horizontal-player .player-timeline .player-epispode-title {
      font-size: 1.23em;
      font-weight: 700;
      /* margin-bottom: 10px; */
      line-height: 1.23em; }
  .horizontal-player .player-toolbuttons {
    display: flex;
    flex: 0.5;
    justify-content: space-between; }
    .horizontal-player .player-toolbuttons .btn-floating {
      color: #989898; }
    .horizontal-player .player-toolbuttons .button-wrapper {
      align-items: center;
      display: flex;
      margin-right: 5px; }
      .horizontal-player .player-toolbuttons .button-wrapper:hover i {
        color: #b3b3b3; }
    .horizontal-player .player-toolbuttons .playlist-button.disabled {
      opacity: 0.2; }
    .horizontal-player .player-toolbuttons .playlist-button i {
      color: #666;
      font-size: 2.33em; }
    .horizontal-player .player-toolbuttons .player-toolbutton {
      background-color: #EEE;
      border-radius: 50%;
      box-sizing: border-box;
      padding: 7px; }
      .horizontal-player .player-toolbuttons .player-toolbutton .active {
        background-color: #000000;
        border-radius: 50%;
        box-sizing: border-box;
        padding: 7px; }
      .horizontal-player .player-toolbuttons .player-toolbutton .material-icons {
        color: #989898; }
  .horizontal-player button.disabled {
    opacity: 0.2; }
  .horizontal-player .player-play-button .material-icons, .horizontal-player player-pause-button .material-icons {
    font-size: 3.33em; }
  .horizontal-player .player-prev-button {
    margin-right: -15px; }
  .horizontal-player .player-next-button {
    margin-left: -15px; }
  .horizontal-player .player-timeline .channel-codename {
    font-size: 0.8em; }
  .horizontal-player .player-timeline .channel-codename {
    font-size: 0.8em;
    margin-top: 0px !important; }
  .horizontal-player .btn-floating i {
    color: #989898; }

li.playlist-item a {
  display: flex; }

li.playlist-item img {
  height: 75px;
  width: 75px; 
  margin-right: 15px; }

@media only screen and (max-width: 992px) {
  .horizontal-player {
    bottom: 50px;
  }
  .horizontal-player.off {
    bottom: -40px; }
  .horizontal-player.hidden {
    bottom: -40px;
  }
}

.search-component {
  transition: 0.5s; }

@media only screen and (max-width: 992px) {
  #root{
    padding-bottom: 50px;
  }
  .search-component {
    background-color: #FFF;
    flex: 0 !important;
    overflow: hidden;
    width: 0px; }
    .search-component.active {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%; }
  .header-ola {
    align-items: center;
    justify-content: center; }
  .searchMobile {
    display: contents; }
  section.navbar.ola-menu {
    bottom: 0;
    background: #FFF;
    flex-direction: row;
    min-height: 50px;
    height: 50px;
    position: fixed;
    top: unset;
    width: 100% !important;
    z-index: 9; }
    section.navbar.ola-menu .logo-ola {
      display: none; }
    section.navbar.ola-menu .navbar_menu ul {
      box-shadow: rgba(0, 0, 0, 0.1) 0 -1px 2px 1px;
      display: flex; }
    section.navbar.ola-menu .navbar_menu ul a {
      align-items: center;
      justify-content: center;
    }
    section.navbar.ola-menu .navbar_menu ul a > span {
      display: none; }
    section.navbar.ola-menu .navbar_menu .menu-item {
      flex: 1;
      text-align: center;
    }
  .trend-list {
    margin-right: -20px;
    overflow-x: auto; }
  .vertical-list {
    overflow-x: auto; }
  .content-colon {
    min-width: 87% !important;
    margin-right: 15px !important; }
  .content-colon.carousel-item {
    min-width: auto !important;
  }
  .horizontal-player .player-controller {
    margin: 0px; }
    .horizontal-player .player-controller .player-prev-button, .horizontal-player .player-controller .player-next-button {
      display: none; }
  .horizontal-player .player-toolbuttons {
    display: none; }
  .horizontal-player .player-timeline {
    margin: 0px;
    max-width: 220px; }
  .horizontal-player .player-epispode-title {
    font-size: 1.1em !important;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .horizontal-player .player-thumb {
    min-width: 60px;
    margin-top: 5px;
    max-height: 60px; } }
@media (max-width: 800px) {
  .player-timeline {
    max-width: 150px;
  }
  .horizontal-player .player-timeline .channel-codename {
    margin-bottom: 0;
  }
  .player-toolbuttons {
    display: block !important;
  }
  .player-toolbuttons .button-wrapper {
    display: none !important;
  }
  .player-toolbuttons .button-wrapper:last-child {
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .horizontal-player .player-timeline {
    max-width: 150px;
    display: block;
  }
  .horizontal-player .player-timeline .player-epispode-title {
    display: block;
    line-height: 20px;
  }
  .horizontal-player .player-timeline .player-epispode-title span a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    height: 20px;
  }
}
.rodape-footer{
  display: block !important;
  flex: 0 !important;
}
@media (max-width: 992px) {
  .rodape-footer > div {
    margin-left: 0
  }
}
@media (max-width: 768px) {
  .continuar-ouvindo-pagination, 
  .minhas-inscricoes-pagination, 
  .em-alta-pagination, 
  .premium-pagination, 
  .recommended-pagination {
    display: none;
  }
}

.carregando {
  width: 2em;
  height: 2em;
  -webkit-animation: rotate 2s linear infinite;
  -moz-animation: rotate 2s linear infinite;
  -ms-animation: rotate 2s linear infinite;
  -o-animation: rotate 2s linear infinite;
  animation: rotate 2s linear infinite;
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.cancelaAssinatura {
  background-color: #ff0000;
  color: #ffffff !important;
  font-weight: 700;
  border-radius: 7px;
  padding: 10px !important; 
  margin-left: 19px;
  margin-right: 19px; 
  align-items: center;
  text-align: center;
}

.dropdownContentList {
  background-color: #fff;
  margin: 0;
  display: none;
  max-width: 320px;
  overflow-y: auto;
  opacity: 0;
  position: absolute;
  left: auto !important;
  top: auto !important;
  right: 0;
  bottom: 0;
  z-index: 9999;
}
.dropdownContentList.active {
  display: block;
  opacity: 1;
}
.dropdownContentList:focus {
  outline: 0;
}
.dropdownContentList li {
  clear: both;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  min-height: 50px;
  line-height: 1.5rem;
  width: 100%;
  text-align: left;
}
.dropdownContentList li:hover, .dropdownContentList li.active {
  background-color: #eee;
}
.dropdownContentList li:focus {
  outline: none;
}
.dropdownContentList li.divider {
  min-height: 0;
  height: 1px;
}
.dropdownContentList li > a,
.dropdownContentList li > span {
  font-size: 16px;
  color: #26a69a;
  /*display: block;*/
  line-height: 22px;
  padding: 14px 16px;
}
.dropdownContentList li > span > label {
  top: 1px;
  left: 0;
  height: 18px;
}
.dropdownContentList li > a > i {
  height: inherit;
  line-height: inherit;
  float: left;
  margin: 0 24px 0 0;
  width: 24px;
}

.item2 {animation: 1s moveItem2 infinite;}
                .item3 {animation: 1s moveItem3 1s infinite;}
                .item4 {animation: 1s moveItem4 2s infinite;}
                .item5 {animation: 1s moveItem5 3s infinite;}
                .item6 {animation: 1s moveItem6 4s infinite;}
                
                @keyframes moveItem2 { 0% { y: 216 } 50% { y: 0 } 100% { y: 216 } }
                @keyframes moveItem3 { 0% { y: 160 } 50% { y: 300 } 100% { y: 160 } }
                @keyframes moveItem4 { 0% { y: 0 }  50% { y: 209 } 100% { y: 0 } }
                @keyframes moveItem5 { 0% { y: 209 } 50% { y: 0 } 100% { y: 209 } }
                @keyframes moveItem6 { 0% { y: 160 } 50% { y: 335 } 100% { y: 160 } }